import { AfterViewInit, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { map, startWith, Subject, takeUntil, timer } from 'rxjs';

import { DialogService } from 'src/app/shared/services/dialog.service';
import { SignalrService } from 'src/app/shared/services/signalr.service';

import { ScreenEmbedInput } from 'src/app/shared/models/services/dialog.model';

@Component({
  selector: 'app-screen-embed-dialog',
  templateUrl: './screen-embed-dialog.component.html',
  styleUrls: ['./screen-embed-dialog.component.scss'],
})
export class ScreenEmbedDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly unsubscribe = new Subject<void>();
  private readonly data = inject(DIALOG_DATA) as ScreenEmbedInput;
  private readonly signalRService = inject(SignalrService);
  private readonly dialogService = inject(DialogService);
  private readonly dialogRef = inject(DialogRef);

  public title = this.data.title;
  public iFrame = this.data.iFrame;
  public thumbnail = this.data.thumbnail;
  public thumbnailFallbackLink = this.data.thumbnailFallbackLink;

  public isMaybeUnsupported$ = timer(5_000).pipe(
    map(() => true),
    startWith(false),
  );

  @ViewChild('iframeContainer') public iframeContainer!: ElementRef<HTMLDivElement>;

  public ngOnInit(): void {
    this.signalRService.teamSettingsUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((teamSettings) => {
      const screenEmbed = teamSettings.embeds.screen;

      if (screenEmbed) {
        this.title = screenEmbed.title;
        this.iFrame = screenEmbed.iFrame;
        this.thumbnail = screenEmbed.thumbnail;
      }
    });
  }

  public ngAfterViewInit(): void {
    const iFrame = this.iframeContainer.nativeElement.getElementsByTagName('iframe')[0];
    iFrame.style.borderRadius = '20px';
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public openEditScreenEmbedDialog(): void {
    this.dialogRef.close();

    this.dialogService
      .openEditScreenEmbedDialog({
        title: this.title,
        urlOrIFrame: this.iFrame,
        thumbnail: this.thumbnail,
        thumbnailFallbackLink: this.thumbnailFallbackLink,
      })
      .subscribe();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
