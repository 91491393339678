import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { DialogButtonIcon } from 'src/app/shared/models/services/dialog.model';

@Component({
  selector: 'app-dialog-button',
  standalone: true,
  templateUrl: './dialog-button.component.html',
  styleUrls: ['./dialog-button.component.scss'],
})
export class DialogButtonComponent {
  @Input({ required: true }) public icon!: DialogButtonIcon;

  @Output() public buttonClick = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public onClick() {
    this.buttonClick.emit();
  }
}
