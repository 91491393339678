<ng-container *transloco="let t; read: 'shared'">
  <div class="container">
    <p>{{ t("dialogs.hideMeeting.text") }}</p>

    <div class="button-container">
      <app-button
        backgroundColor="var(--bnear-danger)"
        border="1.5px solid transparent"
        class="confirm-button absolute-css-values"
        color="white"
        height="unset"
        padding="5px 20px"
        (buttonClick)="hideMeeting()"
      >
        {{ t("dialogs.hideMeeting.confirm") }}
      </app-button>

      <app-button
        backgroundColor="transparent"
        border="1.5px solid var(--bnear-brand)"
        class="absolute-css-values"
        color="var(--bnear-brand)"
        height="unset"
        padding="5px 20px"
        (buttonClick)="cancel()"
      >
        {{ t("dialogs.cancel") }}
      </app-button>
    </div>
  </div>
</ng-container>
