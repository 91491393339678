import { Component, inject, OnDestroy } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { catchError, EMPTY, finalize, Subject, takeUntil, tap } from 'rxjs';
import { ImageCropperComponent } from 'ngx-image-cropper';

import { DialogContainerComponent } from '../shared/container/dialog-container.component';

import { ImagePickerInput, ImagePickerOutput } from 'src/app/shared/models/services/dialog.model';

@Component({
  selector: 'app-image-picker-dialog',
  templateUrl: './image-picker-dialog.component.html',
  styleUrls: ['./image-picker-dialog.component.scss'],
})
export class ImagePickerDialogComponent implements OnDestroy {
  private readonly unsubscribe: Subject<void> = new Subject();
  private readonly dialogContainer: DialogContainerComponent<ImagePickerOutput | undefined>;

  private readonly dialogRef = inject(DialogRef<ImagePickerOutput | undefined>);
  private readonly data = inject<ImagePickerInput>(DIALOG_DATA);

  public format = this.data.format;
  public roundCropper = this.data.roundCropper;
  public aspectRatio = this.data.aspectRatio;
  public imageQuality = this.data.imageQuality;
  public resizeToHeight = this.data.resizeToHeight;
  public resizeToWidth = this.data.resizeToWidth;
  public cropperMinWidth = this.data.cropperMinWidth;
  public containWithinAspectRatio = this.data.containWithinAspectRatio;
  public maintainAspectRatio = this.data.maintainAspectRatio;
  public uploadFn = this.data.uploadFn;

  public type = this.data.type;

  public selectedImage?: File;
  public imageIsLoading?: boolean;
  public uploadInProgress = false;

  @ViewChild('fileInput') public fileInput!: ElementRef;
  @ViewChild(ImageCropperComponent) public imageCropper!: ImageCropperComponent;

  public constructor() {
    this.dialogContainer = this.dialogRef.containerInstance as DialogContainerComponent<ImagePickerOutput | undefined>;
    this.dialogContainer.dialogRef = this.dialogRef;
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public setSelectedImage(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (!target.files || target.files.length === 0) return;

    this.imageIsLoading = true;
    this.selectedImage = target.files[0];
  }

  public cancel(): void {
    this.dialogContainer.close(undefined);
  }

  public close(): void {
    const base64Image = this.imageCropper.crop('base64')?.base64 ?? null;

    if (!this.uploadFn) {
      this.dialogContainer.close({ base64: base64Image });

      return;
    }

    this.uploadInProgress = true;
    if (!base64Image) {
      this.uploadInProgress = false;
      return;
    }

    this.dialogRef.disableClose = true;
    this.uploadFn(base64Image)
      .pipe(
        tap((success) => {
          this.dialogContainer.close({ base64: success ? base64Image : null });
        }),
        catchError(() => {
          this.dialogContainer.close({ base64: null });
          return EMPTY;
        }),
        finalize(() => (this.uploadInProgress = false)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }
}
