import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { catchError, Subject, takeUntil } from 'rxjs';
import { app } from '@microsoft/teams-js';

import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { TeamService } from 'src/app/shared/services/team.service';

import { UserModifiedEmbedEvent } from 'src/app/shared/models/services/application-insights.model';
import { EditScreenEmbedInput } from 'src/app/shared/models/services/dialog.model';
import { EmbedKey } from 'src/app/shared/models/services/team.model';

import { getIsUrlOrIframe } from 'src/app/shared/utils/components/edit-screen-embed-dialog.util';

import { TRANSLATION_KEYS } from 'src/app/shared/constants';

@Component({
  selector: 'app-edit-screen-embed-dialog',
  templateUrl: './edit-screen-embed-dialog.component.html',
  styleUrls: ['./edit-screen-embed-dialog.component.scss'],
})
export class EditScreenEmbedDialogComponent implements OnInit, OnDestroy {
  private readonly embedKey: EmbedKey = 'screen';
  private readonly learnMoreLink;

  private readonly unsubscribe = new Subject<void>();
  private readonly teamService = inject(TeamService);
  private readonly signalRService = inject(SignalrService);
  private readonly dialogService = inject(DialogService);
  private readonly dialogRef = inject(DialogRef);
  private readonly data = inject(DIALOG_DATA) as EditScreenEmbedInput;
  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  private readonly authService = inject(AuthService);

  private readonly initialThumbnail = this.data.thumbnail;
  public thumbnail = this.data.thumbnail;
  public thumbnailFallbackLink = this.data.thumbnailFallbackLink;
  public embedExists = this.data.title !== null && this.data.urlOrIFrame !== null;

  public title = new FormControl<string | null>(this.data.title, [Validators.required, Validators.maxLength(256)]);
  public urlOrIFrame = new FormControl<string | null>(this.data.urlOrIFrame, [Validators.required]);

  public errorMessageKey: string | null = null;
  public saveButtonDisabled = true;
  public deleteButtonDisabled = false;

  public constructor() {
    if (this.authService.context?.app.locale.startsWith('de')) {
      this.learnMoreLink = `https://bnear.io/screen-web-embeds-de/`;
    } else {
      this.learnMoreLink = 'https://bnear.io/screen-web-embeds-en/';
    }
  }

  public ngOnInit(): void {
    this.title.valueChanges.subscribe(() => this.updateVariables());
    this.urlOrIFrame.valueChanges.subscribe(() => ([this.errorMessageKey] = [null, this.updateVariables()]));

    this.signalRService.teamSettingsUpdated.pipe(takeUntil(this.unsubscribe)).subscribe((teamSettings) => {
      const screenEmbed = teamSettings.embeds.screen;

      if (screenEmbed) {
        [this.thumbnail, this.thumbnailFallbackLink] = [screenEmbed.thumbnail, screenEmbed.thumbnailFallbackLink];

        this.title.setValue(screenEmbed.title);
        this.urlOrIFrame.setValue(screenEmbed.iFrame);
      } else {
        [this.thumbnail, this.thumbnailFallbackLink] = [null, null];

        this.title.setValue(null);
        this.urlOrIFrame.setValue(null);
      }

      this.embedExists = screenEmbed !== null;
      this.updateVariables();
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public saveChanges(): void {
    // TODO: Write custom Validator instead
    const isUrlOrIframe = getIsUrlOrIframe(this.urlOrIFrame.value);

    if (isUrlOrIframe === 'invalid') {
      this.errorMessageKey = TRANSLATION_KEYS.shared.dialogs.editScreenEmbed.incorrectInputError;

      return;
    }

    this.dialogRef.disableClose = true;
    [this.saveButtonDisabled, this.deleteButtonDisabled] = [true, true];

    this.applicationInsightsService.logCustomEvent(
      new UserModifiedEmbedEvent(this.embedExists ? 'update' : 'create', Boolean(this.thumbnail)),
    );

    this.teamService
      .createOrUpdateEmbeds({
        embed: {
          key: this.embedKey,
          title: this.title.value!,
          urlOrIFrame: this.urlOrIFrame.value!,
          thumbnail: this.thumbnail,
        },
      })
      .pipe(
        takeUntil(this.unsubscribe),
        catchError((error: HttpErrorResponse) => {
          if (error.status == HttpStatusCode.BadRequest) {
            this.errorMessageKey = TRANSLATION_KEYS.shared.dialogs.editScreenEmbed.notEmbeddableError;

            this.title.markAsPristine();
            this.urlOrIFrame.markAsPristine();
          }

          throw error;
        }),
      )
      .subscribe({
        next: (embeds) => {
          const screenEmbed = embeds.screen!;

          this.title.setValue(screenEmbed.title);
          this.urlOrIFrame.setValue(screenEmbed.iFrame);

          this.thumbnailFallbackLink = screenEmbed.thumbnailFallbackLink;
          this.thumbnail = screenEmbed.thumbnail;

          [this.errorMessageKey] = [null, this.updateVariables()];
          this.dialogRef.close();

          const [title, iFrame] = [this.title.value!, this.urlOrIFrame.value!];
          const [thumbnail, thumbnailFallbackLink] = [this.thumbnail, this.thumbnailFallbackLink];

          this.dialogService.openScreenEmbedDialog({ title, iFrame, thumbnail, thumbnailFallbackLink }).subscribe();
        },
        error: () => this.updateVariables(),
      });
  }

  public close(): void {
    if (this.dialogRef.disableClose) return;

    this.dialogRef.close();
  }

  public openThumbnailPickerDialog(): void {
    this.dialogService.openThumbnailPickerDialog().subscribe((data) => {
      const thumbnail = data?.base64 ?? null;
      const isPristine = thumbnail === this.initialThumbnail;

      if (!isPristine && this.title.valid && this.urlOrIFrame.valid) this.saveButtonDisabled = false;
      else this.updateVariables();

      this.thumbnail = thumbnail;
    });
  }

  public openRemoveScreenDialog(): void {
    this.dialogService.openRemoveScreenDialog().subscribe(({ success }) => {
      if (success) {
        this.dialogRef.disableClose = true;
        [this.saveButtonDisabled, this.deleteButtonDisabled] = [true, true];

        this.teamService
          .createOrUpdateEmbeds({ removeEmbed: { key: 'screen' } })
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            complete: () => ([this.errorMessageKey] = [null, this.updateVariables, this.dialogRef.close()]),
            error: () => this.updateVariables(),
          });
      }
    });
  }

  public openLearnMorePage(): void {
    app.openLink(this.learnMoreLink);
  }

  private updateVariables(): void {
    this.dialogRef.disableClose = false;

    this.saveButtonDisabled =
      this.title.invalid || this.urlOrIFrame.invalid || (this.title.pristine && this.urlOrIFrame.pristine);
    this.deleteButtonDisabled = !this.embedExists;
  }
}
