import { Component, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

import { TOO_MANY_USERS_THRESHOLD } from 'src/app/shared/constants';

@Component({
  selector: 'app-too-many-users-dialog',
  templateUrl: './too-many-users-dialog.component.html',
  styleUrls: ['./too-many-users-dialog.component.scss'],
})
export class TooManyUsersDialogComponent {
  private readonly dialogRef = inject(DialogRef<boolean>);

  public TOO_MANY_USERS_THRESHOLD = TOO_MANY_USERS_THRESHOLD;

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public cancel(): void {
    this.dialogRef.close(false);
  }
}
