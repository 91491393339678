<ng-container *transloco="let t; read: 'shared.dialogs'">
  <div class="container">
    <img class="table" src="assets/img/misc/topic-table.png" />

    <p class="heading">
      {{
        data.updateType === "add" ? t("currentTopicTableCount.add.heading") : t("currentTopicTableCount.remove.heading")
      }}
    </p>

    <p *ngIf="data.updateType === 'add'" class="text">{{ t("currentTopicTableCount.add.text") }}</p>

    <input
      *ngIf="data.updateType === 'add'"
      appAutoFocus
      type="text"
      [formControl]="subject"
      [placeholder]="t('currentTopicTableCount.subjectPlaceholder')"
      (keyup.enter)="updateCurrentTopicTableCount()"
    />

    <div class="button-container">
      <app-button
        class="absolute-css-values"
        height="unset"
        minWidth="226px"
        padding="5px 20px"
        [backgroundColor]="data.updateType === 'remove' ? 'var(--bnear-danger)' : undefined"
        [border]="data.updateType === 'remove' ? '1.5px solid transparent' : undefined"
        [color]="data.updateType === 'remove' ? 'white' : undefined"
        [disabled]="buttonDisabled || (data.updateType === 'add' && subject.invalid)"
        (buttonClick)="updateCurrentTopicTableCount()"
      >
        {{
          data.updateType === "add"
            ? t("currentTopicTableCount.add.confirm")
            : t("currentTopicTableCount.remove.confirm")
        }}
      </app-button>

      <app-button
        backgroundColor="transparent"
        border="1.5px solid var(--bnear-brand)"
        class="absolute-css-values"
        color="var(--bnear-brand)"
        height="unset"
        minWidth="226px"
        padding="5px 20px"
        (buttonClick)="cancel()"
      >
        {{ t("cancel") }}
      </app-button>
    </div>
  </div>
</ng-container>
