import { Component, computed, inject, signal } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { TranslocoDirective } from '@ngneat/transloco';

import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'app-opt-out-meeting-overview-warning-dialog',
  standalone: true,
  imports: [TranslocoDirective, ButtonComponent],
  template: `
    <ng-container *transloco="let t; read: 'shared'">
      <span class="title">{{
        t('dialogs.optOutMeetingOverviewWarning.title', {
          optedInOut: t('dialogs.optOutMeetingOverviewWarning.' + optOutTranslationKey())
        })
      }}</span>

      <span class="subtitle">{{ t('dialogs.optOutMeetingOverviewWarning.subtitle') }}</span>

      <div class="button-container">
        <app-button
          backgroundColor="var(--bnear-brand)"
          border="1.5px solid transparent"
          class="confirm-button absolute-css-values"
          color="white"
          height="unset"
          padding="5px 20px"
          (buttonClick)="dialogRef.close(true)"
        >
          {{ t('dialogs.optOutMeetingOverviewWarning.confirm') }}
        </app-button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./opt-out-meeting-overview-warning-dialog.component.scss'],
})
export class OptOutMeetingOverviewWarningDialogComponent {
  private readonly data = signal<boolean>(inject<boolean>(DIALOG_DATA));

  public readonly dialogRef = inject(DialogRef<boolean>);

  public readonly optOutTranslationKey = computed(() => (this.data() ? 'optedOut' : 'optedIn'));
}
