<div class="dialog-button-container">
  <app-dialog-button icon="close-red" (click)="close()"></app-dialog-button>
</div>

<div *transloco="let tEditScreenEmbedDialog; read: 'shared.dialogs.editScreenEmbed'" class="container">
  <div class="heading">{{ tEditScreenEmbedDialog("heading") }}</div>
  <div class="description">
    {{ tEditScreenEmbedDialog("description") }}
    <span class="learn-more" (click)="openLearnMorePage()">{{ tEditScreenEmbedDialog("learnMore") }}</span>
  </div>

  <div class="content-container">
    <div class="left-container">
      <div class="left-row-1">
        <div class="title">
          <div class="label-heading">{{ tEditScreenEmbedDialog("titleHeading") }}</div>
          <div class="label-description">{{ tEditScreenEmbedDialog("titleDescription") }}</div>

          <input type="text" [formControl]="title" />
        </div>

        <div class="preview-image">
          <div class="label-heading">{{ tEditScreenEmbedDialog("previewImageHeading") }}</div>
          <div class="label-description">{{ tEditScreenEmbedDialog("previewImageDescription") }}</div>

          <app-button fontSize="0.8rem" height="39px" width="100%" (buttonClick)="openThumbnailPickerDialog()">
            {{ tEditScreenEmbedDialog("previewImageButton") }}
          </app-button>
        </div>
      </div>

      <div *transloco="let t" class="left-row-2">
        <div class="embed-code">
          <div class="label-heading">{{ tEditScreenEmbedDialog("embedHeading") }}</div>
          <div class="label-description">{{ tEditScreenEmbedDialog("embedDescription") }}</div>

          <input type="text" [formControl]="urlOrIFrame" />
          <div *ngIf="errorMessageKey" class="error">{{ t(errorMessageKey) }}</div>
        </div>
      </div>
    </div>

    <div class="right-container">
      <div class="screen-preview">
        <img class="screen-preview-img" src="assets/img/misc/screen-preview.png" />

        <div class="screen-container">
          <app-screen-button
            borderRadius="6px"
            fontSize="0.6rem"
            height="50px"
            iconHeight="20px"
            iconWidth="20px"
            padding="3px"
            titlePaddingTop="4px"
            width="70px"
            [disabled]="true"
            [title]="(title.valueChanges | async) || title.value"
          ></app-screen-button>

          <div *ngIf="thumbnail" class="thumbnail-container">
            <img [src]="thumbnail" />
          </div>

          <div *ngIf="!thumbnail && thumbnailFallbackLink" class="thumbnail-container fallback">
            <div class="fallback-container">
              <img [src]="thumbnailFallbackLink" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="buttons-container">
    <app-button fontSize="15px" height="39px" width="250px" [disabled]="saveButtonDisabled" (click)="saveChanges()">{{
      embedExists ? tEditScreenEmbedDialog("save") : tEditScreenEmbedDialog("setup")
    }}</app-button>

    <app-button
      *ngIf="embedExists"
      backgroundColor="white"
      border="1px solid var(--bnear-danger)"
      boxShadow="unset"
      color="var(--bnear-danger)"
      fontSize="15px"
      height="39px"
      width="200px"
      [disabled]="deleteButtonDisabled"
      (click)="openRemoveScreenDialog()"
      >{{ tEditScreenEmbedDialog("remove") }}</app-button
    >
  </div>
</div>
