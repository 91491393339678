import { ChangeDetectionStrategy, Component, ElementRef, HostListener, inject } from '@angular/core';
import { NgStyle } from '@angular/common';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-image-viewer-dialog',
  standalone: true,
  imports: [NgStyle],
  template: ` <img [ngStyle]="{ transform, cursor }" [src]="data" (click)="onImageClick($event)" /> `,
  styleUrls: ['./image-viewer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerDialogComponent {
  private readonly container = inject(ElementRef);
  private readonly dialogRef = inject(DialogRef);

  private isScaled = false;
  private minScaleFactor = 1;
  private maxScaleFactor = 2;

  public readonly data = inject<string>(DIALOG_DATA);

  public cursor: 'zoom-in' | 'zoom-out' = 'zoom-in';
  public transform = `scale(${this.minScaleFactor}) translate(0px, 0px)`;

  @HostListener('click')
  public onHostClick() {
    this.dialogRef.close();
  }

  public onImageClick(event: MouseEvent) {
    event.stopPropagation();

    let newScale: number;
    let newTranslate: [number, number];
    if (this.isScaled) {
      newScale = this.minScaleFactor;
      this.cursor = 'zoom-in';
      newTranslate = [0, 0];
      this.isScaled = false;
    } else {
      newScale = this.maxScaleFactor;
      this.cursor = 'zoom-out';
      const containerWidth = this.container.nativeElement.offsetWidth;
      const containerHeight = this.container.nativeElement.offsetHeight;
      newTranslate = this.zoomToPoint(
        event.offsetX,
        event.offsetY,
        this.maxScaleFactor,
        containerWidth,
        containerHeight,
      );
      this.isScaled = true;
    }
    this.transform = `scale(${newScale}) translate(${newTranslate[0]}px, ${newTranslate[1]}px)`;
  }

  private zoomToPoint(
    zoomPointX: number,
    zoomPointY: number,
    scale: number,
    containerWidth: number,
    containerHeight: number,
  ): [number, number] {
    // Flip the coordinates
    const flippedX = containerWidth - zoomPointX;
    const flippedY = containerHeight - zoomPointY;

    // Calculate the offset of the zoom point from the center of the container
    const offsetX = flippedX - containerWidth / 2;
    const offsetY = flippedY - containerHeight / 2;

    // Calculate the new translate values
    const translateX = offsetX * (1 - scale);
    const translateY = offsetY * (1 - scale);

    return [-translateX, -translateY];
  }
}
