import { Component, HostBinding, HostListener } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { CdkDialogContainer, DialogRef } from '@angular/cdk/dialog';

import { ScaleByOfficePipe } from 'src/app/shared/pipes/scale-by-office.pipe';

import { AnimationData, SlideAnimationState } from 'src/app/shared/models/animation.model';

import { slideInOutTriggered } from 'src/app/shared/utils/animation.util';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  animations: [slideInOutTriggered],
})
export class DialogContainerComponent<TResult> extends CdkDialogContainer {
  private readonly scaleByOfficePipe = new ScaleByOfficePipe();
  private dialogResult?: TResult;

  public dialogRef!: DialogRef<TResult>;
  public animationState: SlideAnimationState = 'visible';

  public boxShadow = '0 0 30px rgb(0 0 0 / 25%)';
  public backgroundColor = 'white';
  public padding = '32px';

  @HostListener('@slideInOutTriggered.done', ['$event'])
  public onFadeFinished(event: AnimationEvent): void {
    const { toState } = event;

    const willBeHidden = (toState as SlideAnimationState) === 'hidden';
    const isHidden = this.animationState === 'hidden';

    if (willBeHidden && isHidden) this.dialogRef.close(this.dialogResult);
  }

  @HostBinding('@slideInOutTriggered')
  public get animation(): AnimationData {
    return { value: this.animationState, params: { slideInMs: 350, slideOutMs: 350 } };
  }

  @HostBinding('style.padding')
  public get getPadding(): string {
    return this.scaleByOfficePipe.transform(this.padding);
  }

  @HostBinding('style.box-shadow')
  public get getBoxShadow(): string {
    return this.boxShadow;
  }

  @HostBinding('style.background-color')
  public get getBackgroundColor(): string {
    return this.backgroundColor;
  }

  public close(result: TResult): void {
    this.dialogResult = result;

    this.animationState = 'hidden';
  }
}
