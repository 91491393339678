import { Component, inject, OnDestroy } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { concatMap, of, Subject, takeUntil } from 'rxjs';
import { catchError, retryWhen, throwError } from 'rxjs';

import { DialogContainerComponent } from '../shared/container/dialog-container.component';

import { CalendarService } from 'src/app/shared/services/calendar.service';
import { OnlineMeetingService } from 'src/app/shared/services/online-meeting.service';

import { HideMeetingInput } from 'src/app/shared/models/services/dialog.model';

import { includeScalingRetryStrategy } from 'src/app/shared/utils/rxjs.util';

@Component({
  selector: 'app-hide-meeting-dialog',
  templateUrl: './hide-meeting-dialog.component.html',
  styleUrls: ['./hide-meeting-dialog.component.scss'],
})
export class HideMeetingDialogComponent implements OnDestroy {
  private readonly onlineMeetingService = inject(OnlineMeetingService);
  private readonly calendarService = inject(CalendarService);

  private unsubscribe: Subject<void> = new Subject();
  private dialogContainer: DialogContainerComponent<boolean | undefined>;

  public readonly dialogRef = inject(DialogRef<boolean | undefined>);
  public readonly data = inject(DIALOG_DATA) as HideMeetingInput;

  public constructor() {
    this.dialogContainer = this.dialogRef.containerInstance as DialogContainerComponent<boolean | undefined>;
    this.dialogContainer.dialogRef = this.dialogRef;
  }

  public hideMeeting(): void {
    this.dialogRef.disableClose = true;

    this.onlineMeetingService
      .hide(this.data)
      .pipe(
        takeUntil(this.unsubscribe),
        retryWhen(
          includeScalingRetryStrategy({
            includedStatusCodes: [HttpStatusCode.ServiceUnavailable],
            maxRetryAttempts: 3,
            scalingDuration: 250,
          }),
        ),
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.Conflict) {
            return of(null);
          }

          return throwError(() => err);
        }),
        concatMap(() => this.calendarService.hideMeeting(this.data.eventId, this.data.joinWebUrl, this.data.iCalUId)),
      )
      .subscribe({
        next: () => {
          this.dialogContainer.close(true);
        },
        error: (err) => {
          this.dialogContainer.close(false);
          throw err;
        },
      });
  }

  public cancel(): void {
    this.dialogContainer.close(undefined);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
