import { Component, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';

import { RemoveScreenOutput } from 'src/app/shared/models/services/dialog.model';

@Component({
  selector: 'app-remove-screen-dialog',
  templateUrl: './remove-screen-dialog.component.html',
  styleUrls: ['./remove-screen-dialog.component.scss'],
})
export class RemoveScreenDialogComponent {
  private readonly dialogRef = inject(DialogRef<RemoveScreenOutput>);

  public remove(): void {
    this.dialogRef.close({ success: true });
  }

  public abort(): void {
    this.dialogRef.close({ success: false });
  }
}
