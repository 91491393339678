export function getIsUrlOrIframe(urlOrIframe: string | null): 'iframe' | 'invalid' | 'url' {
  if (!urlOrIframe) return 'invalid';

  try {
    // eslint-disable-next-line no-unused-vars
    const url = new URL(urlOrIframe);

    return 'url';
  } catch {
    try {
      const domParser = new DOMParser();
      const document = domParser.parseFromString(urlOrIframe, 'text/html');

      const iframe = document.querySelector('iframe');
      const src = iframe?.getAttribute('src');
      if (!src) return 'invalid';

      return 'iframe';
    } catch {
      return 'invalid';
    }
  }
}
