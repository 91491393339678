import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgStyle } from '@angular/common';

import { TranslocoDirective } from '@ngneat/transloco';

import { ScaleByOfficePipe } from 'src/app/shared/pipes/scale-by-office.pipe';

import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
  selector: 'app-screen-button',
  standalone: true,
  templateUrl: './screen-button.component.html',
  styleUrls: ['./screen-button.component.scss'],
  imports: [TranslocoDirective, NgStyle, ScaleByOfficePipe, TooltipDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenButtonComponent {
  @Input({ required: true }) public title?: string | null;

  @Input() public disabled = false;
  @Input() public height = 'unset';
  @Input() public width = 'unset';

  @Input() public minWidth = 'unset';
  @Input() public maxWidth = 'unset';

  @Input() public padding = 'scale-by-office(0.5)';
  @Input() public borderRadius = 'scale-by-office(0.5)';
  @Input() public fontSize = 'scale-by-office(1)';
  @Input() public titlePaddingTop = 'scale-by-office(0.5)';
  @Input() public iconWidth = 'scale-by-office(2)';
  @Input() public iconHeight = 'scale-by-office(2)';

  @Output() public buttonClick = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public onClick() {
    if (this.disabled) return;

    this.buttonClick.emit();
  }
}
