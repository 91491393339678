import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { NgIf } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { concatMap, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonComponent } from '../../button/button.component';
import { DialogContainerComponent } from '../shared/container/dialog-container.component';

import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { CallService } from 'src/app/shared/services/call.service';
import { OnlineMeetingService } from 'src/app/shared/services/online-meeting.service';
import { TeamService } from 'src/app/shared/services/team.service';

import { AutoFocusDirective } from 'src/app/shared/directives/auto-focus.directive';

import { UserModifiedTopicMeetingEvent } from 'src/app/shared/models/services/application-insights.model';
import { CallContext } from 'src/app/shared/models/services/call.model';
import { UpdateCurrentTopicTableCountInput } from 'src/app/shared/models/services/dialog.model';

@Component({
  standalone: true,
  selector: 'app-update-topic-table-count-dialog',
  templateUrl: './update-topic-table-count-dialog.component.html',
  styleUrls: ['./update-topic-table-count-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, ReactiveFormsModule, TranslocoModule, ButtonComponent, AutoFocusDirective],
})
export class UpdateTopicTableCountDialogComponent implements OnDestroy {
  private readonly unsubscribe = new Subject<void>();

  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  private readonly onlineMeetingService = inject(OnlineMeetingService);
  private readonly callService = inject(CallService);
  private readonly teamService = inject(TeamService);

  private readonly dialogRef: DialogRef<boolean | undefined> = inject(DialogRef);

  private readonly dialogContainer: DialogContainerComponent<boolean | undefined>;

  public readonly data: UpdateCurrentTopicTableCountInput = inject(DIALOG_DATA);

  public readonly subject = new FormControl('', [Validators.required, Validators.maxLength(50)]);

  public buttonDisabled = false;

  public constructor() {
    this.dialogContainer = this.dialogRef.containerInstance as DialogContainerComponent<boolean | undefined>;
    this.dialogContainer.dialogRef = this.dialogRef;
  }

  public updateCurrentTopicTableCount(): void {
    [this.dialogRef.disableClose, this.buttonDisabled] = [true, true];

    this.applicationInsightsService.logCustomEvent(new UserModifiedTopicMeetingEvent(this.data.updateType));

    const operation$: Observable<unknown> =
      this.data.updateType === 'add'
        ? this.callService.createOrGetCall(CallContext.TopicMeeting, this.subject.value!, this.data.index).pipe(
            withLatestFrom(this.teamService.teamInfoData$),
            concatMap(([onlineMeeting, teamInfoData]) =>
              this.onlineMeetingService.addUsersToChats(
                [onlineMeeting.joinWebUrl!],
                teamInfoData!.myTeam!.users.map((u) => u.objectId),
              ),
            ),
          )
        : this.onlineMeetingService.deleteTopicMeeting(this.data.index!);

    operation$.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => this.dialogContainer.close(true),
      error: (err) => {
        this.dialogContainer.close(false);

        throw err;
      },
    });
  }

  public cancel(): void {
    this.dialogContainer.close(undefined);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
