<div *transloco="let t; read: 'shared.dialogs.imagePicker'" class="dialog-content">
  <div class="dialog-title">{{ t("title." + type) }}</div>

  <input #fileInput accept=".jpg,.jpeg,.png" style="display: none" type="file" (change)="setSelectedImage($event)" />

  <div class="cancel-button" (click)="cancel()">
    <img src="assets/img/icons/close.svg" />
  </div>

  <div [ngClass]="{ 'image-placeholder-container': true, circle: roundCropper }">
    <div *ngIf="imageIsLoading === true" class="placeholder">
      <app-spinner size="30px"></app-spinner>
    </div>

    <div *ngIf="!selectedImage && imageIsLoading !== true" class="placeholder">
      {{ t("uploadText") }}
      <br />
      {{ t("fileFormat") }}
      <br />
      <br />
      <app-button class="image-picker-primary-button" (buttonClick)="fileInput.click()">
        {{ t("selectImage") }}
      </app-button>
    </div>

    <image-cropper
      [aspectRatio]="aspectRatio"
      [containWithinAspectRatio]="containWithinAspectRatio ?? false"
      [cropperMinWidth]="cropperMinWidth ?? 0"
      [format]="format"
      [imageFile]="selectedImage"
      [imageQuality]="imageQuality ?? 92"
      [maintainAspectRatio]="maintainAspectRatio ?? true"
      [ngStyle]="selectedImage && imageIsLoading === false ? {} : { display: 'none' }"
      [resizeToHeight]="resizeToHeight ?? 0"
      [resizeToWidth]="resizeToWidth ?? 0"
      [roundCropper]="roundCropper"
      (imageLoaded)="imageIsLoading = false"
    ></image-cropper>
  </div>

  <app-button
    *ngIf="selectedImage && imageIsLoading !== true"
    class="image-picker-subtle-button"
    (buttonClick)="fileInput.click()"
  >
    {{ t("selectOtherImage") }}
  </app-button>

  <app-button
    *ngIf="selectedImage && imageIsLoading !== true"
    class="image-picker-primary-button done-btn"
    [disabled]="uploadInProgress"
    (buttonClick)="close()"
  >
    <div *ngIf="uploadInProgress">{{ t("loadingText") }}</div>
    <div *ngIf="!uploadInProgress">{{ t("finish") | uppercase }}</div>
  </app-button>
</div>
