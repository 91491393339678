<ng-container *transloco="let tRemoveScreen; read: 'shared.dialogs.removeScreen'">
  <div class="container">
    <p class="heading">{{ tRemoveScreen("heading") }}</p>

    <div class="button-container">
      <app-button
        backgroundColor="var(--bnear-danger)"
        border="1.5px solid transparent"
        class="absolute-css-values"
        color="white"
        height="100%"
        padding="5px 20px"
        width="250px"
        (click)="remove()"
      >
        {{ tRemoveScreen("remove") }}
      </app-button>

      <app-button
        backgroundColor="transparent"
        border="1.5px solid var(--bnear-brand)"
        class="absolute-css-values"
        color="var(--bnear-brand)"
        height="100%"
        padding="5px 20px"
        width="250px"
        (click)="abort()"
        >{{ tRemoveScreen("abort") }}
      </app-button>
    </div>
  </div>
</ng-container>
