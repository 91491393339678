import { inject, Injectable, Injector } from '@angular/core';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { GlobalPositionStrategy, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';

import { concatMap, map, Observable, skip, take, takeUntil } from 'rxjs';
import { base64ToFile } from 'ngx-image-cropper';

import { DeactivateTeamDialogComponent } from '../components/dialogs/deactivate-team/deactivate-team-dialog.component';
import { EditScreenEmbedDialogComponent } from '../components/dialogs/edit-screen-embed/edit-screen-embed-dialog.component';
import { HideMeetingDialogComponent } from '../components/dialogs/hide-meeting/hide-meeting-dialog.component';
import { ImagePickerDialogComponent } from '../components/dialogs/image-picker/image-picker-dialog.component';
import { ImageViewerDialogComponent } from '../components/dialogs/image-viewer/image-viewer-dialog.component';
import { OptOutMeetingOverviewWarningDialogComponent } from '../components/dialogs/opt-out-meeting-overview-warning/opt-out-meeting-overview-warning-dialog.component';
import { RemoveScreenDialogComponent } from '../components/dialogs/remove-screen/remove-screen-dialog.component';
import { ScreenEmbedDialogComponent } from '../components/dialogs/screen-embed/screen-embed-dialog.component';
import { SettingsDialogComponent } from '../components/dialogs/settings/settings-dialog.component';
import { DialogContainerComponent } from '../components/dialogs/shared/container/dialog-container.component';
import { TooManyUsersDialogComponent } from '../components/dialogs/too-many-users/too-many-users-dialog.component';
import { UpdateTopicTableCountDialogComponent } from '../components/dialogs/update-topic-table-count/update-topic-table-count-dialog.component';

import { AuthService } from './auth.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';

import {
  EditScreenEmbedInput,
  ExtendedDialogConfig,
  HideMeetingInput,
  ImageFormat,
  ImagePickerInput,
  ImagePickerOutput,
  RemoveScreenOutput,
  ScreenEmbedInput,
} from '../models/services/dialog.model';
import { UpdateCurrentTopicTableCountInput } from '../models/services/dialog.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly authService = inject(AuthService);
  private readonly injector = inject(Injector);

  private readonly positionBuilder = inject(OverlayPositionBuilder);
  public readonly dialog = inject(Dialog);

  public openThumbnailPickerDialog(): Observable<ImagePickerOutput | undefined> {
    const data: ImagePickerInput = {
      format: 'webp',
      roundCropper: false,
      imageQuality: 10,
      resizeToHeight: 432,
      resizeToWidth: 432,
      aspectRatio: 16 / 9,
      containWithinAspectRatio: true,
      type: 'thumbnail',
    };

    return this.openDialog<ImagePickerOutput, ImagePickerInput, ImagePickerDialogComponent>(
      ImagePickerDialogComponent,
      data,
      { height: '521px', hasBackdrop: false },
    );
  }

  public openAvatarPickerDialog(hasBackdrop: boolean): Observable<ImagePickerOutput | undefined> {
    const userService = this.injector.get(UserService);

    const format: ImageFormat = this.authService.isExternalAccount ? 'webp' : 'jpeg';
    const uploadFn = (base64: string): Observable<boolean> => userService.updateMyAvatar(base64ToFile(base64));
    const data: ImagePickerInput = {
      format: format,
      roundCropper: true,
      imageQuality: 10,
      resizeToHeight: 432,
      resizeToWidth: 432,
      aspectRatio: 1,
      containWithinAspectRatio: true,
      type: 'avatar',
      uploadFn,
    };

    return this.openDialog<ImagePickerOutput, ImagePickerInput, ImagePickerDialogComponent>(
      ImagePickerDialogComponent,
      data,
      { hasBackdrop },
    );
  }

  public openCompanyLogoUploadDialog(): Observable<ImagePickerOutput | undefined> {
    const tenantService = this.injector.get(TenantService);

    const uploadFn = (base64: string): Observable<boolean> => tenantService.uploadLogo(base64);
    const data: ImagePickerInput = {
      format: 'png',
      roundCropper: false,
      aspectRatio: 1 / 1,
      cropperMinWidth: 128,
      maintainAspectRatio: false,
      resizeToWidth: 1000,
      resizeToHeight: 1000,
      type: 'company',
      uploadFn,
    };

    return this.openDialog<ImagePickerOutput, ImagePickerInput, ImagePickerDialogComponent>(
      ImagePickerDialogComponent,
      data,
    );
  }

  public openHideMeetingDialog(data: HideMeetingInput): Observable<boolean | undefined> {
    return this.openDialog<boolean | undefined, HideMeetingInput, HideMeetingDialogComponent>(
      HideMeetingDialogComponent,
      data,
      { width: '550px' },
    );
  }

  public openUpdateCurrentTopicTableCountDialog(
    data: UpdateCurrentTopicTableCountInput,
  ): Observable<boolean | undefined> {
    return this.openDialog<
      boolean | undefined,
      UpdateCurrentTopicTableCountInput,
      UpdateTopicTableCountDialogComponent
    >(UpdateTopicTableCountDialogComponent, data);
  }

  public openImageViewerDialog(data: string): Observable<unknown> {
    const config = { container: undefined, width: 'unset' };

    return this.openDialog<unknown, string, ImageViewerDialogComponent>(ImageViewerDialogComponent, data, config);
  }

  public openRemoveScreenDialog(): Observable<RemoveScreenOutput> {
    return this.openDialog<RemoveScreenOutput, unknown, RemoveScreenDialogComponent>(RemoveScreenDialogComponent);
  }

  public openEditScreenEmbedDialog(data: EditScreenEmbedInput): Observable<unknown> {
    return this.openDialog<unknown, EditScreenEmbedInput, EditScreenEmbedDialogComponent>(
      EditScreenEmbedDialogComponent,
      data,
      { width: '1000px', height: '500px' },
    );
  }

  public openScreenEmbedDialog(data: ScreenEmbedInput): Observable<unknown> {
    return this.openDialog<unknown, ScreenEmbedInput, ScreenEmbedDialogComponent>(ScreenEmbedDialogComponent, data, {
      width: '90vw',
      height: '90vh',
      padding: '0',
      boxShadow: 'unset',
      backgroundColor: 'transparent',
    });
  }

  public openDeactivateTeamDialog(): Observable<boolean> {
    return this.openDialog<boolean, undefined, DeactivateTeamDialogComponent>(
      DeactivateTeamDialogComponent,
      undefined,
      {
        width: '550px',
      },
    );
  }

  public openTooManyUsersDialog(): Observable<boolean> {
    return this.openDialog<boolean, undefined, TooManyUsersDialogComponent>(TooManyUsersDialogComponent, undefined, {
      width: '550px',
    });
  }

  public openSettingsDialog(): Observable<boolean> {
    return this.openDialog<boolean, undefined, SettingsDialogComponent>(SettingsDialogComponent, undefined, {
      width: '50%',
      minWidth: '600px',
      padding: '42px 52px',
      disableClose: true,
    });
  }

  public openOptOutWarningDialog(data: boolean): Observable<undefined> {
    return this.openDialog<undefined, boolean, OptOutMeetingOverviewWarningDialogComponent>(
      OptOutMeetingOverviewWarningDialogComponent,
      data,
      { width: '550px', hasBackdrop: false },
    );
  }

  private openDialog<Output, Input, Component>(
    component: ComponentType<Component>,
    data?: Input,
    config: DialogConfig<Input, DialogRef<Output, Component>> & ExtendedDialogConfig = {},
  ): Observable<Output> {
    return this.authService.isMobileWidth$.pipe(
      take(1),
      concatMap((isMobileWidth) => {
        let positionStrategy: GlobalPositionStrategy | undefined;
        if (isMobileWidth) positionStrategy = this.positionBuilder.global().bottom('0');

        const defaultConfig = {
          positionStrategy,
          container: DialogContainerComponent,
          backdropClass: 'dialog-default-backdrop',
          autoFocus: 'first-heading',
        };

        if (!config.width) config.width = '600px';

        const dialogRef = this.dialog.open(component, { ...defaultConfig, ...config, data });
        const containerInstance = dialogRef.containerInstance as DialogContainerComponent<Output>;

        containerInstance.animationState = 'fromBottom';
        if (config.boxShadow) containerInstance.boxShadow = config.boxShadow;
        if (config.backgroundColor) containerInstance.backgroundColor = config.backgroundColor;
        if (config.padding) containerInstance.padding = config.padding;

        this.authService.isMobileWidth$.pipe(skip(1), takeUntil(dialogRef.closed)).subscribe((isMobileWidth) => {
          if (isMobileWidth) {
            dialogRef.overlayRef.updatePositionStrategy(this.positionBuilder.global().bottom('0'));
          } else {
            dialogRef.overlayRef.updatePositionStrategy(
              this.positionBuilder.global().centerHorizontally().centerVertically(),
            );
          }
        });

        return dialogRef.closed.pipe(map((value) => value as Output));
      }),
    );
  }
}
