import { Component, inject, OnInit } from '@angular/core';
import { AsyncPipe, KeyValue } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { TranslocoDirective } from '@ngneat/transloco';

import { ButtonComponent } from '../../button/button.component';
import { DialogButtonComponent } from '../shared/button/dialog-button.component';

import { DialogService } from 'src/app/shared/services/dialog.service';
import { UserService } from 'src/app/shared/services/user.service';

import { SafePipe } from 'src/app/shared/pipes/safe.pipe';

import { filterNullish } from 'src/app/shared/utils/rxjs.util';

@Component({
  selector: 'app-settings-dialog',
  standalone: true,
  imports: [AsyncPipe, TranslocoDirective, ButtonComponent, DialogButtonComponent, SafePipe],
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent implements OnInit {
  private readonly dialogRef = inject(DialogRef<boolean>);
  private readonly dialogService = inject(DialogService);
  private readonly userService = inject(UserService);

  private readonly uploadAvatarSubject = new Subject<void>();
  private readonly updateSettingSubject = new Subject<KeyValue<string, unknown>>();

  private readonly _avatar$ = combineLatest([this.userService.userInfo$, this.userService.avatar$]).pipe(
    map(([userInfo, avatar]) => userInfo?.user.avatar ?? avatar),
  );

  private readonly settings$ = combineLatest([
    this.userService.userInfo$.pipe(filterNullish()),
    this._avatar$.pipe(take(1)),
  ]).pipe(
    map(([userInfo, avatar]) => ({ ...userInfo.settings, avatar })),
    shareReplay(1),
  );

  private readonly uploadAvatar$ = this.uploadAvatarSubject.pipe(
    switchMap(() => this.dialogService.openAvatarPickerDialog(false)),
    map((value) => value?.base64),
    startWith(null),
  );

  private readonly updateSetting$ = this.updateSettingSubject.pipe(
    withLatestFrom(this.settings$),
    switchMap(([{ key, value }, settings]) =>
      this.userService.updateUser({ settings: { ...settings, [key]: value } }).pipe(map(() => ({ key, value }))),
    ),
    switchMap(({ key, value }) => {
      if (key !== 'optOutMeetingOverview') return of({ key, value });

      return merge(
        this.dialogService.openOptOutWarningDialog(value as boolean).pipe(switchMap(() => EMPTY)),
        of({ key, value }),
      );
    }),
    startWith(null),
    shareReplay(1),
  );

  // TODO: Consider integrating this into the observable flow to avoid the need for a separate subscription
  private readonly settingsChanged$ = combineLatest([this.settings$.pipe(take(1)), this.settings$]).pipe(
    map(
      ([oldSettings, newSettings]) =>
        oldSettings.avatar !== newSettings.avatar ||
        oldSettings.autoCheckIn !== newSettings.autoCheckIn ||
        oldSettings.enhancedPrivacy !== newSettings.enhancedPrivacy ||
        oldSettings.optOutMeetingOverview !== newSettings.optOutMeetingOverview,
    ),
    distinctUntilChanged(),
    shareReplay(1),
    takeUntilDestroyed(),
  );

  public readonly disabled$ = merge(
    this.updateSettingSubject.pipe(map(() => true)),
    this.updateSetting$.pipe(map(() => false)),
  ).pipe(startWith(false), distinctUntilChanged(), shareReplay(1));

  public readonly avatar$ = merge(
    this.settings$.pipe(map((settings) => settings.avatar)),
    this.uploadAvatar$.pipe(filterNullish()),
  );

  public readonly autoCheckIn$ = merge(
    this.settings$.pipe(map((settings) => settings.autoCheckIn)),
    this.updateSetting$.pipe(
      filterNullish(),
      filter(({ key }) => key === 'autoCheckIn'),
      map(({ value }) => value as boolean),
    ),
  );

  public readonly enhancedPrivacy$ = merge(
    this.settings$.pipe(map((settings) => settings.enhancedPrivacy)),
    this.updateSetting$.pipe(
      filterNullish(),
      filter(({ key }) => key === 'enhancedPrivacy'),
      map(({ value }) => value as boolean),
    ),
  );

  public readonly optOutMeetingOverview$ = merge(
    this.settings$.pipe(map((settings) => settings.optOutMeetingOverview)),
    this.updateSetting$.pipe(
      filterNullish(),
      filter(({ key }) => key === 'optOutMeetingOverview'),
      map(({ value }) => value as boolean),
    ),
  ).pipe(shareReplay(1));

  public enhancedPrivacyDisabled$ = combineLatest([this.disabled$, this.optOutMeetingOverview$]).pipe(
    map(([disabled, optOutMeetingOverview]) => optOutMeetingOverview || disabled),
  );

  public ngOnInit(): void {
    this.settingsChanged$.subscribe();
  }

  public onChange(key: string, event: Event): void {
    const value = (event.target as HTMLInputElement).checked;
    this.updateSettingSubject.next({ key, value });
  }

  public uploadAvatar(): void {
    this.uploadAvatarSubject.next();
  }

  public close(): void {
    this.settingsChanged$
      .pipe(
        take(1),
        tap((settingsChanged) => this.dialogRef.close(settingsChanged)),
      )
      .subscribe();
  }
}
