import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements OnChanges, AfterViewInit {
  private readonly hostElement = inject(ElementRef);

  @Input('appAutoFocusEnabled') public enabled = true;

  @Output() public focused = new EventEmitter<HTMLElement>();

  public ngOnChanges(changes: SimpleChanges): void {
    if ('enabled' in changes && this.enabled) {
      this.hostElement.nativeElement.focus({ preventScroll: true });
      this.focused.emit(this.hostElement.nativeElement);
    }
  }

  public ngAfterViewInit(): void {
    if (this.enabled) {
      this.hostElement!.nativeElement.focus({ preventScroll: true });
      this.focused.emit(this.hostElement!.nativeElement);
    }
  }
}
