<ng-container *transloco="let t; read: 'shared.dialogs.settings'">
  <app-dialog-button icon="close-red" (buttonClick)="close()" />
  <div class="heading">
    <img src="assets/img/icons/settings.svg" />
    <span>{{ t("title") }}</span>
  </div>

  <div class="settings-container">
    <div class="row">
      <div class="title-container">
        <span class="title">{{ t("avatar.title") }}</span>
      </div>
      <div class="row">
        <img class="avatar" [src]="avatar$ | async" />
        <app-button
          backgroundColor="var(--bnear-brand)"
          border="1.5px solid transparent"
          class="absolute-css-values"
          color="white"
          height="unset"
          padding="5px 20px"
          [disabled]="(disabled$ | async)!"
          (buttonClick)="uploadAvatar()"
        >
          {{ t("changeImage") }}
        </app-button>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="title-container">
        <span class="title">{{ t("autoCheckIn.title") }}</span>
        <span class="subtitle" [innerHTML]="t('autoCheckIn.subtitle') | safe: 'html'"></span>
      </div>
      <div class="row checkbox-size">
        <input
          id="autoCheckIn"
          type="checkbox"
          [checked]="autoCheckIn$ | async"
          [disabled]="disabled$ | async"
          (change)="onChange('autoCheckIn', $event)"
        />
        <label for="autoCheckIn"></label>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="title-container">
        <span class="title">{{ t("enhancedPrivacy.title") }}</span>
        <span
          class="subtitle"
          [class.disabled]="optOutMeetingOverview$ | async"
          [innerHTML]="t('enhancedPrivacy.subtitle') | safe: 'html'"
        ></span>
      </div>
      <div class="row checkbox-size">
        <input
          id="enhancedPrivacy"
          type="checkbox"
          [checked]="enhancedPrivacy$ | async"
          [disabled]="enhancedPrivacyDisabled$ | async"
          (change)="onChange('enhancedPrivacy', $event)"
        />
        <label for="enhancedPrivacy"></label>
      </div>
    </div>
    <div class="row margin-top-10-px">
      <div class="title-container">
        <span class="subtitle" [innerHTML]="t('optOutMeetingOverview.subtitle') | safe: 'html'"></span>
      </div>
      <div class="row checkbox-size">
        <input
          id="optOutMeetingOverview"
          type="checkbox"
          [checked]="optOutMeetingOverview$ | async"
          [disabled]="disabled$ | async"
          (click)="onChange('optOutMeetingOverview', $event)"
        />
        <label for="optOutMeetingOverview"></label>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="title-container">
        <span class="title">{{ t("notifications.title") }}</span>
        <span class="subtitle" [innerHTML]="t('notifications.subtitle') | safe: 'html'"></span>
      </div>
    </div>
  </div>
</ng-container>
