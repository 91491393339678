<ng-container *transloco="let t; read: 'shared'">
  <span class="title">{{ t("dialogs.deactivateTeam.title") }}</span>
  <span>{{ t("dialogs.deactivateTeam.subtitle") }}</span>

  <div class="button-container">
    <app-button
      backgroundColor="var(--bnear-danger)"
      border="1.5px solid transparent"
      class="confirm-button absolute-css-values"
      color="white"
      height="unset"
      padding="5px 20px"
      (buttonClick)="confirm()"
    >
      {{ t("dialogs.deactivateTeam.confirm") }}
    </app-button>

    <app-button
      backgroundColor="transparent"
      border="1.5px solid var(--bnear-brand)"
      class="absolute-css-values"
      color="var(--bnear-brand)"
      height="unset"
      padding="5px 20px"
      (buttonClick)="cancel()"
    >
      {{ t("dialogs.cancel") }}
    </app-button>
  </div>
</ng-container>
