<div class="buttons">
  <app-dialog-button class="settings" icon="settings" (buttonClick)="openEditScreenEmbedDialog()"></app-dialog-button>
  <app-dialog-button icon="close-red" (buttonClick)="closeDialog()"></app-dialog-button>
</div>

<div *ngIf="isMaybeUnsupported$ | async" class="unsupported-notice">
  <span *transloco="let t; read: 'shared.dialogs.screenEmbed'">{{ t("maybeUnsupported") }}</span>
</div>

<div #iframeContainer class="iframe-container" [innerHtml]="iFrame | safe: 'html'"></div>
